import RegiaF1 from '../../../src/media/Fasilitas/Fasilitas Regia (1).webp'
import RegiaF2 from '../../../src/media/Fasilitas/Fasilitas Regia (2).webp'
import RegiaF3 from '../../../src/media/Fasilitas/Fasilitas Regia (3).webp'
import RegiaF4 from '../../../src/media/Fasilitas/Fasilitas Regia (4).webp'


const RegiaFimages =[
RegiaF1,RegiaF2,RegiaF3,RegiaF4
]

export default RegiaFimages;