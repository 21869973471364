import JasmiaL1 from '../../../src/media/SCG Landscape (1)/Jasmia (1).webp'
import JasmiaL0 from '../../../src/media/Fasilitas/Kawasan Jasmia (1).webp'
import JasmiaL2 from '../../../src/media/SCG Landscape (1)/Jasmia (2).webp'
import JasmiaL3 from '../../../src/media/SCG Landscape (1)/Jasmia (3).webp'
import JasmiaL4 from '../../../src/media/SCG Landscape (1)/Jasmia (4).webp'
import JasmiaL5 from '../../../src/media/SCG Landscape (1)/Jasmia (5).webp'
import JasmiaL6 from '../../../src/media/SCG Landscape (1)/Jasmia (6).webp'
import JasmiaL7 from '../../../src/media/SCG Landscape (1)/Jasmia (7).webp'
import JasmiaL8 from '../../../src/media/SCG Landscape (1)/Jasmia (8).webp'
import JasmiaL9 from '../../../src/media/SCG Landscape (1)/Jasmia (9).webp'
import JasmiaL10 from '../../../src/media/SCG Landscape (1)/Jasmia (10).webp'
import JasmiaL11 from '../../../src/media/SCG Landscape (1)/Jasmia (11).webp'
import JasmiaL12 from '../../../src/media/SCG Landscape (1)/Jasmia (12).webp'
import JasmiaL13 from '../../../src/media/SCG Landscape (1)/Jasmia (13).webp'
import JasmiaL14 from '../../../src/media/SCG Landscape (1)/Jasmia (14).webp'
import JasmiaL15 from '../../../src/media/SCG Landscape (1)/Jasmia (15).webp'
import JasmiaL16 from '../../../src/media/SCG Landscape (1)/Jasmia (16).webp'
import JasmiaL17 from '../../../src/media/SCG Landscape (1)/Jasmia (17).webp'
import JasmiaL18 from '../../../src/media/SCG Landscape (1)/Jasmia (18).webp'
import JasmiaL19 from '../../../src/media/SCG Landscape (1)/Jasmia (19).webp'
import JasmiaL20 from '../../../src/media/SCG Landscape (1)/Jasmia (20).webp'



const JasmiaLimages =[
JasmiaL1,JasmiaL0,JasmiaL2,JasmiaL3,JasmiaL4,JasmiaL5,JasmiaL6,JasmiaL7,JasmiaL8,JasmiaL9,JasmiaL10,JasmiaL11,JasmiaL12,JasmiaL13,JasmiaL14,JasmiaL15,JasmiaL16,JasmiaL17,JasmiaL18,JasmiaL19,JasmiaL20
]

export default JasmiaLimages;