import JasmiaF1 from '../../../src/media/Fasilitas/Fasilitas Jasmia (1).webp'
import JasmiaF2 from '../../../src/media/Fasilitas/Fasilitas Jasmia (2).webp'
import JasmiaF3 from '../../../src/media/Fasilitas/Fasilitas Jasmia (3).webp'
import JasmiaF4 from '../../../src/media/Fasilitas/Fasilitas Jasmia (4).webp'
import JasmiaF5 from '../../../src/media/Fasilitas/Fasilitas Jasmia (5).webp'


const JasmiaFimages =[
JasmiaF1,JasmiaF2,JasmiaF3,JasmiaF4,JasmiaF5,
]

export default JasmiaFimages;