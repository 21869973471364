import React from "react";
import "./Jasmia.scss";
import Produk1 from "./Produk1/Produk1.jsx";
import logoviola from "../../media/logo/Jasmia.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import JasmiaLimages from "./JasmiaL.js";
import JasmiaFimages from "./fasilitasjasmia.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import stokviola from "../../media/Siteplan SCG/Jasmia.webp";

const rumah = () => {
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const items = [
    "Clubhouse",
    "Swimming Pool",
    "Children Playground",
    "Multifunction Room",
    "Communal Park",
    "Face Recognition System Gate",
    "Security 24 Hours",
  ];
  return (
    <div id="jasmia" className="rumahjasmia">
      <div className="logo-jasmia">
        <img src={logoviola} alt="logo-jasmia" />
      </div>
      <div className="herojasmia">
        <div className="slider-jasmia">
          <Slider {...settings1}>
            {JasmiaLimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>

        <div className="desk-jasmia">
          <div className="highlight"> Rumah Mewah Siap Huni, Free PPN</div>
          Jasmia Residence dirancang dengan desain klasik modern yang dapat
          memberikan keindahan dan kemewahan yang abadi dari masa ke masa.
          Memadukan keseimbangan antara kesegaran alam serta gaya hidup yang
          modern dan penuh warna. Dengan keamanan 24 jam dan fasilitas
          berkualitas.
        </div>
      </div>
      <div>
        <div className="Judul"></div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
      <div className="fasilitas-jasmia">
        <div className="slider-fasilitasjasmia">
          <Slider {...settings2}>
            {JasmiaFimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
      </div>
      <ul className="fasilitasjasmia-list">
        {items.map((item, index) => (
          <li key={index}>
            <FontAwesomeIcon icon={faCheckDouble} color="#534E73" />
            <span> &nbsp; {item}</span>
          </li>
        ))}
      </ul>
      <div className="stok-jasmia">
        <div className="gambar-stokjasmia">
          <img src={stokviola} alt="stok_jasmia" />
        </div>
        <div className="desk-stokjasmia">
          <h1>Informasi Unit</h1>
          <h2>Total Produk Dipasarkan : 203 Unit</h2>
          <h3>Sisa Stok Unit : 82 Unit</h3>
          <h4>Produk Terjual : 121 Unit</h4>
          <h5>Total Produk Belum Dipasarkan : 51 Unit</h5>
        </div>
      </div>
    </div>
  );
};

export default rumah;
