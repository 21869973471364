import React from "react";
import "./promo.scss";
import penawaran from "../../media/Hardsell_SCG.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285212809191&text=Halo+Ricchi,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20rumah%20Summarecon%20Crown%20Gading%20(Promo)+https://marketing-crowngading.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="judulpromo">
      <div id="promo" className="judul">
        <h1>OUR PROMO</h1>
      </div>
      <div id="promo" className=" containerPenawaran">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free PPN</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Insentif Hunian Ratusan Juta
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free CCTV</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Bathtub</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smart Door Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Voucher Belanja Puluhan Juta
              </span>
            </div>

            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Instan Approval (Hanya Butuh KTP,KK dan NPWP)
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} alt={penawaran} />
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
