import React from "react";
import "./Regia.scss";
import Produk1 from "./Produk1/Produk1.jsx";
import logoviola from "../../media/logo/regia.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RegiaLimages from "./RegiaL.js";
import RegiaFimages from "./RegiaF.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import stokviola from "../../media/Siteplan SCG/Regia.webp";

const rumah = () => {
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const items = [
    "Clubhouse",
    "Swimming Pool",
    "Children Playground",
    "Multifunction Room",
    "Communal Park",
    "Face Recognition System Gate",
    "Security 24 Hours",
  ];
  return (
    <div id="regia" className="rumahregia">
      <div className="logo-regia">
        <img src={logoviola} alt="logo-regia" />
      </div>
      <div className="heroregia">
        <div className="slider-regia">
          <Slider {...settings1}>
            {RegiaLimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
        <div className="desk-regia">
          <div className="highlight"> Rumah Modern Siap Huni, Free PPN</div>
          Nama Regia diambil dari nama latin bunga flamboyan, Delonix regia.
          Karena biasanya digunakan sebagai tanaman hias, sebagian besar
          bunganya akan ditanam di taman dan sepanjang jalan untuk menghiasi
          cluster. Dengan konsep modern kontemporer, Regia Residence menyediakan
          hunian sempurna bagi keluarga baru untuk tumbuh dan berkembang
          bersama, menyeimbangkan kehidupan modern dikelilingi oleh nuansa alam
          yang sejuk. Dirancang oleh Nataneka Arsitek yang didirikan oleh
          Sukendro Sukendar Priyoso dan Jeffry Sandy pada Oktober 1999.
        </div>
      </div>
      <div>
        <div className="Judul"></div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
      <div className="fasilitas-regia">
        <div className="slider-fasilitasregia">
          <Slider {...settings2}>
            {RegiaFimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
      </div>
      <ul className="fasilitasregia-list">
        {items.map((item, index) => (
          <li key={index}>
            <FontAwesomeIcon icon={faCheckDouble} color="white" />
            <span> &nbsp; {item}</span>
          </li>
        ))}
      </ul>
      <div className="stok-regia">
        <div className="gambar-stokregia">
          <img src={stokviola} alt="stok_regia" />
        </div>
        <div className="desk-stokregia">
          <h1>Informasi Unit</h1>
          <h2>Total Produk Dipasarkan : 414 Unit</h2>
          <h3>Unit Batalan : 4 Unit</h3>
          <h4>Produk Terjual : 414 Unit</h4>
          <h5>Total Produk Belum Dipasarkan : 8 Unit</h5>
        </div>
      </div>
    </div>
  );
};

export default rumah;
