import React from "react";
import "./Vanica.scss";
import Produk1 from "./Produk1/Produk1.jsx";
import logoviola from "../../media/logo/Logo Vanica.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import VanicaLimages from "./VanicaL.js";
import VanicaFimages from "./VanicaF.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import stokviola from "../../media/Siteplan SCG/Vanica.png";

const rumah = () => {
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const items = [
    "Clubhouse",
    "Swimming Pool",
    "Children Playground",
    "Multifunction Room",
    "Communal Park",
    "Face Recognition System Gate",
    "Security 24 Hours",
  ];
  return (
    <div id="regia" className="rumahvanica">
      <div className="logo-vanica">
        <img src={logoviola} alt="logo-vanica" />
      </div>
      <div className="herovanica">
        <div className="slider-vanica">
          <Slider {...settings1}>
            {VanicaLimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
        </div>
        <div className="desk-vanica">
          Vanica Residence adalah klaster perumahan terbaru yang dikembangkan
          oleh Summarecon Crown Gading, berlokasi di kawasan utara-timur
          Jakarta. Klaster ini menawarkan hunian tiga lantai dengan desain
          arsitektur modern tropis yang elegan, ditandai dengan atap asimetris
          dan elemen kayu vertikal yang memberikan kesan mewah pada fasad
          bangunan. Dilengkapi dengan berbagai fasilitas di dalam cluster,
          seperti Swimming Pool, Clubhouse, Children Playground, Communal Park
          dan Security 24 Hours
        </div>
      </div>
      <div>
        <div className="Judul"></div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
      <div className="fasilitas-vanica">
        <div className="slider-fasilitasvanica">
          <Slider {...settings2}>
            {VanicaFimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
        </div>
      </div>
      <ul className="fasilitasvanica-list">
        {items.map((item, index) => (
          <li key={index}>
            <FontAwesomeIcon icon={faCheckDouble} color="white" />
            <span> &nbsp; {item}</span>
          </li>
        ))}
      </ul>
      <div className="stok-vanica">
        <div className="gambar-stokvanica">
          <img src={stokviola} alt="stok_vanica" />
        </div>
        <div className="desk-stokvanica">
          <h1>Informasi Unit</h1>
          <h2>Total Produk Dipasarkan : 42 Unit</h2>
          <h3>Unit Batalan : N/A</h3>
          <h4>Produk Terjual : 30 Unit</h4>
          <h5>Total Produk Belum Dipasarkan : N/A </h5>
        </div>
      </div>
    </div>
  );
};

export default rumah;
