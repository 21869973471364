import React from "react";
import "./Siteplan.scss";
import Comingsoon from "../../media/Site Plan SCG.webp";

const soon = () => {
  return (
    <div id="Siteplan" className="container-soon">
      <hr />
      <div className="nama">
        <h1>Siteplan Summarecon Crown Gading</h1>
      </div>
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={Comingsoon} alt="maps" />
      </div>
    </div>
  );
};

export default soon;
