import VanicaL1 from '../../../src/media/SCG Landscape (1)/Vanica (1).webp'
import VanicaL2 from '../../../src/media/SCG Landscape (1)/Vanica (2).webp'
import VanicaL3 from '../../../src/media/SCG Landscape (1)/Vanica (3).webp'
import VanicaL4 from '../../../src/media/SCG Landscape (1)/Vanica (4).webp'
import VanicaL5 from '../../../src/media/SCG Landscape (1)/Vanica (5).webp'
import VanicaL6 from '../../../src/media/SCG Landscape (1)/Vanica (6).webp'
import VanicaL7 from '../../../src/media/SCG Landscape (1)/Vanica (7).webp'
import VanicaL8 from '../../../src/media/SCG Landscape (1)/Vanica (8).webp'
import VanicaL9 from '../../../src/media/SCG Landscape (1)/Vanica (9).webp'
import VanicaL10 from '../../../src/media/SCG Landscape (1)/Vanica (10).webp'


const VanicaLimages =[
VanicaL1,VanicaL2,VanicaL3,VanicaL4,VanicaL5,VanicaL6,VanicaL7,VanicaL8,VanicaL9,VanicaL10
]

export default VanicaLimages;