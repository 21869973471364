import VanicaF1 from '../../../src/media/Fasilitas/Fasilitas Vanica (1).webp'
import VanicaF2 from '../../../src/media/Fasilitas/Fasilitas Vanica (2).webp'
import VanicaF3 from '../../../src/media/Fasilitas/Fasilitas Vanica (3).webp'
import VanicaF4 from '../../../src/media/Fasilitas/Fasilitas Vanica (4).webp'
import VanicaF5 from '../../../src/media/Fasilitas/Fasilitas Vanica (5).webp'
import VanicaF6 from '../../../src/media/Fasilitas/Fasilitas Vanica (6).webp'

const VanicaFimages =[
VanicaF1,VanicaF2,VanicaF3,VanicaF4,VanicaF5,VanicaF6
]

export default VanicaFimages;