import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar.jsx";
import Banner from "../Section/Banner/banner.jsx";
import About from "../Section/About/about.jsx";
import Vanica from "../Section/Vanica/Vanika.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Progress from "../Section/Progress/progress.jsx";
import Viola from "../Section/Rumah/Viola.jsx";
import Regia from "../Section/Regia/Regia.jsx";
import Jasmia from "../Section/Jasmia/Jasmia.jsx";
import Siteplan from "../Section/Siteplan/Siteplan.jsx";
import Ruko from "../Section/Ruko/ruko.jsx";
import Surrounding from "../Section/Surrounding Area/surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import Future from "../Section/Future/future.jsx";
import VanicaP from "../Section/VanicaP/Vanica.jsx";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import tombol from "../media/tombol.webp";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285212809191&text=Halo+Ray%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Summarecon%20Crown%20Gading%20https://marketing-crowngading.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Banner />
      <About />
      <Vanica />
      <Promo />
      <Progress />
      <Viola />
      <Regia />
      <VanicaP />
      <Jasmia />
      <Siteplan />
      <Ruko />
      <Future />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
