import Surrounding0 from '../../../src/media/Fasilitas/Kawasan (1).webp'
import Surrounding01 from '../../../src/media/Fasilitas/Kawasan (2).webp'
import Surrounding1 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (1).webp'
import Surrounding2 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (2).webp'
import Surrounding3 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (3).webp'
import Surrounding4 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (4).webp'
import Surrounding5 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (5).webp'
import Surrounding6 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (6).webp'
import Surrounding7 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (7).webp'
import Surrounding8 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (8).webp'
import Surrounding9 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (9).webp'
import Surrounding10 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (10).webp'
import Surrounding11 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (11).webp'
import Surrounding12 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (12).webp'
import Surrounding13 from '../../../src/media/Surrounding Area SCG/Surrounding Area SCG (13).webp'

const Surroundingimages =[
Surrounding0,Surrounding01,Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6,Surrounding7,Surrounding8,Surrounding9,Surrounding10,Surrounding11,Surrounding12,Surrounding13
]

export default Surroundingimages;